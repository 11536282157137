<template>
  <div class="CreateAndUpdateProvider_Info">
    <div class="CreateAndUpdateProvider_InfoTitle"><InfoPseudoIcon /> Лимиты для UAH карт:</div>
    <div class="CreateAndUpdateProvider_InfoLabel">Ввод за одну операцию:</div>
    <div class="CreateAndUpdateProvider_InfoText">
      от <span class="CreateAndUpdateProvider_InfoNumber">10</span>
      <span class="CreateAndUpdateProvider_InfoCurrency"> UAH</span> до
      <span class="CreateAndUpdateProvider_InfoNumber">29.000</span>
      <span class="CreateAndUpdateProvider_InfoCurrency"> UAH</span>
    </div>
    <div class="CreateAndUpdateProvider_InfoLabel">Дневной лимит на одну карту:</div>
    <div class="CreateAndUpdateProvider_InfoText">
        <span class="CreateAndUpdateProvider_InfoNumber">100.000</span> 
        <span class="CreateAndUpdateProvider_InfoCurrency"> UAH</span>
    </div>
    <div class="CreateAndUpdateProvider_InfoTitle"><InfoPseudoIcon /> Комиссия для UAH карт:</div>
    <div class="CreateAndUpdateProvider_InfoLabel">Ввод:</div>
    <div class="CreateAndUpdateProvider_InfoText">
      <span class="CreateAndUpdateProvider_InfoNumber">0.8% + 5 </span>
      <span class="CreateAndUpdateProvider_InfoCurrency"> UAH</span>
    </div>
  </div>
</template>

<script>
import { InfoPseudoIcon } from '@/assets/icons/arrows';
export default {
  components: {
    InfoPseudoIcon,
  }
}
</script>

<style lang="scss">
  @import './ProviderInfo.scss';
</style>