<template>
  <div 
    class="CreateAndUpdateProvider_CurrenciesList"
    :class="{
      'CreateAndUpdateProvider_CurrenciesList--error': isError,
    }"
  >
    <div class="CreateAndUpdateProvider_CurrenciesList_Title">Currencies <span class="requireStar">*</span></div>
    <div class="CreateAndUpdateProvider_CurrenciesList_Items">
      <label
        class="CreateAndUpdateProvider_CurrenciesList_Item"
        :key="currency"
        v-for="currency in availableCurrency"
      >
        <Checkbox
          class="CreateAndUpdateProvider_CurrenciesList_ItemCheckbox"
          :inputType="checkTypeInput"
          :label="currency" 
          :value="selectedCurrency.indexOf(currency) != -1"
          ref="checkbox" 
          @input="changeSelectedCurrency"
        />
        <SquareCoinIcon
          class="CreateAndUpdateProvider_CurrenciesList_ItemIcon"
          :currency="currency" />
        <div class="CreateAndUpdateProvider_CurrenciesList_ItemTitle">
          {{ currency }}
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import Checkbox from '@/components/_common/FormElements/Checkbox/Checkbox';
import { mapState } from 'vuex';

export default {
  components: {
    SquareCoinIcon,
    Checkbox,
  },
  data: () => ({
    isError: false,
  }),
  props: {
    providerType: {
      type: String,
      default: ''
    },
    selectedCurrency: {
      type: Array,
      default: [],
    },
    availableCurrency: {
      type: Array,
      default: [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('exchange', ['currencies']),
    checkTypeInput() {
      if(this.providerType === 'CRYPTO_NODE') {
        return 'radio'
      }

      if(this.providerType === 'BILLLINE') {
        return 'checkbox'
      }

      return 'checkbox'
    }
  },
  /*watch: {
    selectedCurrency(value) {
      console.log(value)
    }
  },*/
  methods: {
    changeSelectedCurrency(value) {   
      let selectedCurrency = []
      this.$refs.checkbox.forEach(checkbox => {
        if (checkbox.$el.getElementsByTagName('input')[0].checked) {
          selectedCurrency.push(checkbox.$attrs.label)
        }
      })
      this.$emit('changeSelectedCurrency', selectedCurrency);
      this.$nextTick(() => this.isValid());
    },
    isValid() {
      let isValid = true
      if (this.required && this.selectedCurrency.length == 0) {
        isValid = false
      }
      this.isError = !isValid
      return isValid
    }
  },
};
</script>
<style lang="scss">
@import './CurrenciesList.scss';
</style>